import * as React from 'react';
import ReactTable, { Column, Filter, ReactTableFunction } from 'react-table';
import 'react-table/react-table.css';
import styled from 'styled-components';
import { Textfield } from '../textfield';

interface TableProps {
    id?: string;
    loading?: boolean;
    data: any[];
    absolute?: boolean;
    columns: Column[];
    pagination?: boolean;
    mobile?: boolean;
    manual?: boolean;
    pages?: number;
    page?: number;
    defaultPageSize?: number;
    pageSizeOptions?: number[];
    onClick?(item?: any): void;
    onPage?(pageIndex: number): void;
    onPageSizeChange?(newPageSize: number, newPage: number): void;
}

const Container = styled.div`
    width: 100%;
    position: relative;
`;

const StyledTable = styled(ReactTable)<TableProps>`
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    div.rt-thead {
        padding-right: 15px;

        .rt-th:focus {
            outline: none;
        }
    }

    div.rt-thead.-filters input {
        font-size: 12px;
        padding: 5px;
        height: auto;
    }

    div.rt-tbody {
        div.rt-tr-group {
            max-height: 100px;
        }

        div.rt-tr:not(.-padRow) {
            cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};

            &:hover {
                background: #eee;
            }
        }

        div.rt-td {
            &.align-right {
                text-align: right;
            }

            &.align-center {
                text-align: center;
            }
        }
    }

    div.pagination-bottom div.-pagination {
        button {
            border-radius: 0;
        }

        div.-center {
            display: ${(props) => (props.mobile ? 'none' : 'inherit')};
        }
    }
`;

function defaultFilterFn(filter: Filter, row: any, _column: any): boolean {
    const value = String(row[filter.id]).toLowerCase();

    return value.startsWith(String(filter.value).toLowerCase());
}

export const Table = (props: TableProps) => {
    const {
        data,
        columns,
        loading,
        onClick,
        mobile,
        page,
        pages,
        pageSizeOptions = [50, 100, 200],
        defaultPageSize = 50,
        pagination = true,
        manual = false
    } = props;

    const PAGE_SIZE_KEY = props.id ? `table-${props.id}-page-size` : undefined;
    const pageSize =
        PAGE_SIZE_KEY && sessionStorage.getItem(PAGE_SIZE_KEY)
            ? Number(sessionStorage.getItem(PAGE_SIZE_KEY))
            : defaultPageSize;

    const [statePageSize, setStatePageSize] = React.useState(pageSize);
    const ref = React.useRef<HTMLDivElement | null>(null);

    function getTrProps(_state, rowInfo?: any) {
        return {
            onClick: (_e, handleOriginal) => {
                props.onClick?.(rowInfo ? rowInfo.original : undefined);
                handleOriginal?.();
            }
        };
    }

    function onPageChange(pageIndex: number) {
        ref.current?.scrollIntoView();
        props.onPage?.(pageIndex);
    }

    function onPageSizeChange(newPageSize: number, newPage: number) {
        if (PAGE_SIZE_KEY) {
            sessionStorage.setItem(PAGE_SIZE_KEY, String(newPageSize));
        }

        setStatePageSize(newPageSize);

        props.onPageSizeChange?.(newPageSize, newPage);
    }

    return (
        <Container ref={ref}>
            <StyledTable
                manual={manual}
                noDataText=""
                FilterComponent={FilterComponent}
                defaultFilterMethod={defaultFilterFn}
                /* @ts-ignore */
                mobile={mobile}
                onClick={onClick}
                loading={loading}
                data={data}
                columns={columns}
                getTrProps={getTrProps}
                showPageJump={false}
                showPagination={pagination}
                pages={pages}
                page={page}
                pageText=""
                pageSize={statePageSize}
                defaultPageSize={defaultPageSize}
                pageSizeOptions={pageSizeOptions}
                onPageChange={(...args) => onPageChange(...args)}
                onPageSizeChange={onPageSizeChange}
                style={{ position: props.absolute ? 'absolute' : undefined }}
            />
        </Container>
    );
};

export function clearTablePageIndexStorage(id: string) {
    sessionStorage.removeItem(`table-${id}-page-index`);
}

export function FilterComponent(props: {
    column: Column;
    filter: any;
    onChange: ReactTableFunction;
    key?: string;
}) {
    return (
        <Textfield
            key={props.key}
            placeholder={`Filter by ${props.column.Header}...`}
            value={props.filter ? props.filter.value : ''}
            onChange={props.onChange}
        />
    );
}
